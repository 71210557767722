
import Icon from "@/components/Icon.vue";
import PrintPage2 from '@/components/PrintPage2.vue';
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { GroupTypeQuestion } from "@/dtos/reports/CalculateResultGroupTypeQuestionDTO";
import { useAlert } from '@/hooks/alert';
import ExameService from "@/services/ExameService";
import ReportService from "@/services/ReportService";
import vm from "@/viewModels/MainViewModel";
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";

interface CertificateState {
    calcs: GroupTypeQuestion[];
    loading: boolean;
    QRCodeUrl: string;
}


const CertificateCEView = defineComponent({
    components: { PrintPage2, Icon },
    setup(_props, context) {
        const { replace } = useRouter();
        const alert = useAlert();
        const exam = JSON.parse(localStorage.getItem('certificate')!) as GestorExamDTO;
        const examQuestions = ref<ExamResponseDTO>();

        const dateObject = new Date(exam.schedulingDate ?? new Date());

        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();


        const state = reactive<CertificateState>({
            calcs: [],
            loading: true,
            QRCodeUrl: ''
        });

        const onLoadDispatch = () => {
            const message = { type: 'onLoad', content: '' };
            window.parent.postMessage(message, '*');
        };

        const onLoadErrorDispatch = (errorMessage: string) => {
            const message = { type: 'onLoadError', content:  errorMessage };
            window.parent.postMessage(message, '*');
        };

        const afterPrint = () => {
            context.emit('afterPrint');
        };

        const loadReport = async () => {
            const [request] = await ReportService.CalculateResultGroupTypeQuestion(exam.metadata!.schedulingId);
            request.then(async resp => {
                const [examAdmin] = ExameService.GetExameGestor(exam.metadata!.schedulingId);
                state.calcs = resp;
                state.loading = false;
                examQuestions.value = await examAdmin;

                await nextTick();

                setTimeout(() => {
                    window.print();
                    onLoadDispatch();
                }, 1000);
                
            }).catch(error => {
                onLoadErrorDispatch(error?.toString() ?? "");
                state.loading = true;
                alert({ title: "Não foi possivel carregar o resultado", message: error.message, actions: [{ title: "Voltar", action: () => replace('exams') }] });
            });
        };

        onMounted(async () => {
            window.addEventListener('afterprint', afterPrint);
            await loadReport();
        });

        onUnmounted(async () => {
            window.removeEventListener('afterprint', afterPrint);
        });


        return { state, print, exam, day, month, year, examQuestions, vm };
    },
});

export default CertificateCEView;
